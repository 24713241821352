import React from "react"
import { graphql } from "gatsby"

import { DefaultLayout } from "../layouts/Default"
import { Hero } from "../components/Hero"
import { SEO } from "../components/SEO"
import { CourseCard, CourseGrid } from "../components/CourseCard"
import siteData from "../site-data"

const ClassesPage = ({ data }) => {
  const courses = data.allSanityClass.edges
  const studentCount = siteData.studentCount

  const title = "The Best React Native Courses"
  const description = `Browse our catalog of ${
    courses.length
  } React Native courses and learn alongside ${studentCount.toLocaleString()}+ other developers how to build mobile apps with React Native, the right way.`

  return (
    <DefaultLayout>
      <SEO title={title} description={description} />

      <Hero title={title} subtitle={description} />

      <CourseGrid>
        {courses.map(({ node }) => (
          <CourseCard
            key={node.slug}
            slug={`/${node.slug}/`}
            title={node.title}
            price={node.price}
            description={node.description}
            updated={node.lastUpdated}
          />
        ))}
      </CourseGrid>
    </DefaultLayout>
  )
}

export default ClassesPage

export const query = graphql`
  {
    allSanityClass(
      sort: { fields: lastUpdated, order: DESC }
      filter: { visible: { eq: true } }
    ) {
      edges {
        node {
          title
          description
          lastUpdated
          price
          slug
        }
      }
    }
  }
`
